import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container } from 'reactstrap'

const HeaderWrapper = styled.header`
  height: ${props => props.height};
  position: relative;
`

const HeaderText = styled(Container)`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2rem;
  }
`

const Header = ({ headerImage, title, subtitle, height, children }) => {
  const headerImg = getImage(headerImage)

  return (
    <HeaderWrapper height={height}>
      <GatsbyImage
        image={headerImg}
        alt=""
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: height,
          zIndex: -1,
          filter: 'brightness(65%)',
        }}  
      />

      <HeaderText>
        {title && <h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}

        {children && (
          <div>
            {children}
          </div>
        )}
      </HeaderText>
    </HeaderWrapper>
  )
}

export default Header
