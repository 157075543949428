import React from 'react'
import { graphql } from 'gatsby'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Page from '../components/page'
import Helmet from 'react-helmet'

const JobsPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Job Vacancies | Whickham Thorns Outdoor Activity Centre</title>
      </Helmet>
      <Header
        headerImage={data.headerImage}
        title="Job Vacancies"
        height="20rem"
      />
      <Page>
        <Container>
          <p>There are no open vacancies at Whickham Thorns at present.</p>
        </Container>
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query JobsQuery {
    headerImage: file(relativePath: { regex: "/volunteer/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default JobsPage
